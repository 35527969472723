<template>
  <c-box>
    <c-flex
      :display="['none', 'flex']"
      width="100%"
      max-width="1270px"
      margin-inline="auto"
      align-items="center"
      gap="80px"
      padding-block="20px"
      margin-top="16px"
    >
      <c-box
        display="flex"
        align-items="center"
        font-size="16px"
        font-weight="400"
        color="primary.400"
        gap="20px"
        cursor="pointer"
        @click="$router.push({ name: 'client.index' })"
      >
        <inline-svg
          :src="require('@/assets/icon-chevron-left.svg')"
          height="20"
          width="20"
        />
        Kembali
      </c-box>

      <BreadcrumbPath
        margin-top="0px"
        :paths="breadcrumbLabel"
      />
    </c-flex>
    <c-box
      position="relative"
      display="flex"
      width="100%"
      height="auto"
      margin-inline="auto"
      :background-color="['#F2F2F2', '#FFF']"
      :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
      :border-radius="['0px', '16px']"
      :padding="['1rem', '1.5rem']"
      :min-height="['calc(100vh - 62px)', 'calc(100vh - 220px)']"
    >
      <c-flex
        v-if="isLoadingProgram"
        width="100%"
        flex-direction="column"
        justify-content="center"
        align-items="center"
        padding="128px 24px"
      >
        <c-spinner
          thickness="4px"
          speed="0.65s"
          empty-color="green.200"
          color="primary.400"
          size="xl"
          margin-bottom="16px"
        />
        <c-text
          color="primary.400"
          font-weight="500"
        >
          Memuat data Panduan Makan...
        </c-text>
      </c-flex>
      <EmptyState 
        v-else-if="!isHaveMealPlanMonths"
        title="Kamu belum memiliki program aktif"
        description="Yuk beli program agar mendapatkan panduan makan yang sesuai untukmu."
        button-text="Beli Program"
        button-route="client.select-program"
      />
      <EmptyState 
        v-else-if="isFirstMealPlanPending"
        title="Rencana makanmu masih kosong"
        description="Ahli gizi kami masih merencanakan panduan makan terbaik buat kamu. Mohon ditunggu, ya!"
        button-text="Beranda"
        button-route="client.index"
      />
      <c-box
        v-else-if="programMealPlanMonths.length > 0"
        width="100%"
        :background-color="['#FFF', 'transparent']"
        :padding="['16px', '0px']"
      >
        <c-grid
          :template-columns="['repeat(1, 1fr)', 'repeat(2, 1fr)']"
          :gap="['16px', '20px']"
          margin-bottom="24px"
        >
          <c-flex
            v-for="(item, i) in programMealPlanMonths"
            :key="i"
            width="100%"
            align-items="center"
            background-color="#FFF"
            :padding="['16px', '20px']"
            border-radius="8px"
            gap="8px"
            :box-shadow="['2px 2px 10px rgba(0, 0, 0, 0.15)', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
            :cursor="item?.status === 'pending' ? 'not-allowed' : 'pointer'"
            :opacity="item?.status === 'pending' ? '0.7' : '1'"
            :class="i === 0 ? 'client_meal-plan_1' : ''"
            @click="onClickMealPlanMonth(item)"
          >
            <c-flex
              flex-grow="1"
              justify-content="space-between"
              align-items="center"
            >
              <c-box
                color="neutral.black"
                :font-size="['14px', '18px']"
                font-weight="500"
              >
                Bulan {{ item?.month }}
              </c-box>
              <c-box
                v-if="item?.status === 'done'"
                :font-size="['12px', '16px']"
                font-weight="500"
                color="primary.400"
              >
                Program Selesai
              </c-box>
              <c-box
                v-else-if="item?.status === 'active'"
                :font-size="['12px', '16px']"
                font-weight="500"
                color="info.400"
              >
                Program Aktif
              </c-box>
              <c-box
                v-else-if="item?.status === 'pending'"
                flex-shrink="0"
                width="20px"
                height="20px"
              >
                <inline-svg
                  :src="require('@/assets/icons/icon-lock-green.svg')"
                  stroke="#008C81"
                />
              </c-box>
            </c-flex>
            <c-box
              flex-shrink="0"
              width="20px"
              height="20px"
            >
              <inline-svg
                :src="require('@/assets/icon-chevron-right.svg')"
                stroke="#000"
              />
            </c-box>
          </c-flex>
        </c-grid>
        <c-box
          text-align="center"
        >
          <BaseButton
            :min-width="['250px', '350px']"
            :height="['50px']"
            border-radius="50px"
            @click="$router.push({ name: 'client.select-program' })"
          >
            Tambah Panduan Makan
          </BaseButton>
        </c-box>
      </c-box>
    </c-box>
  </c-box>
</template>

<script>
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'
import BaseButton from '@/components/elements/base-button.vue'
import EmptyState from '@/views/client/meal-plan/widgets/empty-state.vue'
import {
  reqClient_mealPlans_read_put,
} from '@/requests/dietela-api/client/meal-plan'

export default {
  components: {
    BreadcrumbPath,
    BaseButton,
    EmptyState,
  },
  beforeRouteLeave(to, from, next) {
    if (!this.isTourActive) {
      next()
    }
  },
  props: {
    isHaveMealPlanMonths: {
      type: Boolean,
      default: false,
    },
    programMealPlanMonths: {
      type: Array,
      default: () => [],
    },
    isLoadingProgram: {
      type: Boolean,
      default: false,
    },
    activeProgram: {
      type: [Object],
      default: () => {},
    },
    isTourActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tourOptions: {
        debug: true,
        useKeyboardNavigation: false,
        startTimeout: 500,
      },
      tourSteps: [],
    }
  },
  computed: {
    breadcrumbLabel() {
      return [
        {
          label: 'Dashboard',
          href: '/',
        },
        {
          label: 'Panduan Makan',
          isCurrent: true,
        },
      ]
    },
    isFirstMealPlanPending() {
      return this.programMealPlanMonths.length === 0
    },
  },
  watch: {},
  methods: {
    async onClickMealPlanMonth(item) {
      if (item?.status === 'pending' || this.isTourActive) return
      this.$router.push({
        name: 'client.meal-plan.meal-plan-schedule',
        params: { month: item?.month },
        query: {
          ...this.$route.query,
        },
      })
    },
  },
}
</script>

<style>

</style>